import { setCookie, getCookie } from "@/utils/cookies.ts";
import { useTranslations } from "@/i18n/utils";
import React, { useEffect, useRef, useState } from "react";
import { navigate } from "astro:transitions/client";

import BadThemeAr from "@/assets/images/bad_theme_ar.png";
import BadThemeEn from "@/assets/images/bad_theme_en.png";

type SamsungInternetThemeAlertProps = {
  lang: "ar" | "en";
};

const isSamsungBrowser = /SamsungBrowser/.test(navigator.userAgent);

const SamsungInternetThemeAlert: React.FC<SamsungInternetThemeAlertProps> = ({
  lang,
}) => {
  const isUnderstood = getCookie("samsung_browser_theme_alert");

  const t = useTranslations(lang);
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isSamsungBrowser) {
      setTimeout(() => dialogRef.current?.showModal(), 250);
    }
  }, []);

  const handleIUnderstand = () => {
    setCookie("samsung_browser_theme_alert", "true");
    navigate(window.location.pathname);
  };

  if (isUnderstood) {
    return null;
  }

  return (
    <dialog ref={dialogRef} className="modal modal-bottom sm:modal-middle">
      <div className="modal-box">
        <h3 className="font-bold text-lg">{t("dialog.smg_theme.title")}</h3>
        <div className={"w-full flex gap-4 my-4 items-center justify-around"}>
          <div className={"w-full flex flex-col items-center gap-4"}>
            <img
              src={
                lang === "ar"
                  ? "/screenshots/phone_light_ar.png"
                  : "/screenshots/phone_light_en.png"
              }
              alt="correct tawakal theme"
              loading={"eager"}
              decoding={"async"}
              className="w-max max-h-52 border border-base-200 rounded-lg"
            />
            <span className={"text-xs text-center"}>
              {t("dialog.smg_theme.app_original_theme")}
            </span>
          </div>
          <span className="icon-[mingcute--arrow-right-line] w-6 h-6 max-w-6 max-h-6 min-w-6 min-h-6"></span>
          <div className={"w-full flex flex-col items-center gap-4 pt-4"}>
            <img
              src={lang === "ar" ? BadThemeAr.src : BadThemeEn.src}
              alt="correct tawakal theme"
              loading={"eager"}
              decoding={"async"}
              className="w-max max-h-52 border border-base-200 rounded-lg"
            />
            <span className={"text-xs text-center"}>
              {t("dialog.smg_theme.samsung_forced_dark_mode")}
            </span>
          </div>
        </div>
        <p className="py-4 text-sm">{t("dialog.smg_theme.content")}</p>
        <div className="modal-actionw-full">
          <div className="btn btn-primary w-full" onClick={handleIUnderstand}>
            {t("dialog.smg_theme.understand")}
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default SamsungInternetThemeAlert;
