import { getCookie } from "@/utils/cookies.ts";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";

try {
  // SplashScreen
  await SplashScreen.hide();

  // Status Bar
  await StatusBar.setOverlaysWebView({ overlay: false });

  const theme = getCookie("settings#theme");
  const lang = getCookie("settings#lang");

  if (theme === "dark") {
    await StatusBar.setStyle({ style: Style.Dark });
    await StatusBar.setBackgroundColor({ color: "#202020" });
  }

  if (theme === "light") {
    await StatusBar.setStyle({ style: Style.Light });
    await StatusBar.setBackgroundColor({ color: "#ede2cc" });
  }
} catch (e) {
  console.log(e);
}
